[data-ff-el='root'].ff-64a7256c349daf2cf867be4d .ff-64a7256c349daf2cf867be4d__form {
    padding: 0 !important;
}

#fd-form-64a7256c349daf2cf867be4d input {
    border-radius: 5px;
    padding: 16.5px 14px;
    background-color: white;
}

[data-ff-el="root"].ff-64a7256c349daf2cf867be4d .ff-64a7256c349daf2cf867be4d__title {
    margin: 0 !important;
    margin-top: 10px !important;
}

[data-ff-el="root"].ff-64a7256c349daf2cf867be4d .ff-64a7256c349daf2cf867be4d__subtitle {
    margin: 0 !important;
}

button.ff-64a7256c349daf2cf867be4d__button {
    width: 300px !important;
    background: white !important;
    color: rgba(28,28,28,1) !important;
}

[data-ff-el="root"].ff-64a7256c349daf2cf867be4d .fd-has-success .fd-form-success {
    margin: 15px 0 0;
    font-weight: 700;
    color: white;
}