a.custom-mindbody-link {
    font-family: 'Menlo', 'Lato';
    color: rgba(28,28,28,1);
    white-space: nowrap;
    font-weight: 700;
    text-decoration: none;
    background-color: rgba(210,241,62,1);
    border-radius: 8px;
    padding: 8px 18px;
}

a.custom-mindbody-link:hover {
    font-weight: bold;
    cursor: pointer;
}